import React, { useState, useEffect, useContext } from 'react'
import Animation from '../Animations/Animation';
import styled from 'styled-components'
import Paypal from './Paypal'

import { ShopContext } from '../context/ShopContext'
import SaleApproved from './SaleApproved'
import PM_Animation from '../Animations/IMG_Animations/PM_Animation'
import CLOUDS_Animation_1 from '../Animations/IMG_Animations/CLOUDS_Animation_1';

import { 
    ITEM_Telephone_Shirt,
    ITEM_Bear_Keychain,
    shop_item_tags,
    items,
    Order_Item
 } from './items'
 
const useInterval = (handler, time) => {
    const [ intervalState, setIntervalState ] = useState(null)

    useEffect(() => {
        if(!intervalState){
            const myInterval = setInterval(handler, time)
            setIntervalState(myInterval)
        }
    },[intervalState])
    return [ intervalState, setIntervalState ]
}

// TODO
    // focus modal open on cart item click
    // remove from cart option
    // checkout modal
    // large image focus modal
    // local storage 
    // scroll bar on the inside before the cart items

const isDev = false


const temp = [
    {
        reference_id : 'Test_1',
        description: "Shunk Shirt Temp",
        amount: {
            currency_code: "CAD",
            value: 26.50
        }
    },
    {
        reference_id : "Test_2",
        description: "KeyChain",
        amount: {
            currency_code: "CAD",
            value: 5.50
        }
    }
]

const tags = [
    'shirts',
    'sweaters',
    'accessories',
    'keychains',
    'CDs',
    'tapes',
    'tickets',
    'exclusives'
]

// Populate innitial states for the shop based on the available shop items.

const temp_innitial_item_image_Arr = []
const temp_innitial_order_details_Arr = []
items.forEach( _ => temp_innitial_item_image_Arr.push(0))
items.forEach( item => temp_innitial_order_details_Arr.push(new Order_Item(item)))

const Shop = () => {
    
    const shopContext = useContext(ShopContext)
    const [ isCheckout, setIsCheckout ] = useState(false)
    const [ isSaleApproved, setIsSaleApproved ] = useState(false)
    const [ Shop_Item_Images_State, set_Shop_Item_Images_State ] = useState(temp_innitial_item_image_Arr)
    const [ order_Details_Arr, set_Order_Details_Arr ] = useState(temp_innitial_order_details_Arr)
    const [ isMouseOverCartItem, setIsMouseOverCartItem ] = useState(false)
    const [ mouseOverCartItemIndex, setMouseOverCartItemIndex ] = useState(null)
    const [ animationFrame, setAnimationFrame ] = useState(1)
    const [ cloudAnimationFrame, setCloudAnimationFrame ] = useState(1)

    const intervalFunc = () => {
        setAnimationFrame( state => { console.log(state) ; return state+1 })
    }
    useInterval(intervalFunc, 1500)

    useEffect(() => {
        if(isSaleApproved){
            setIsCheckout(false)
        }
    },[setIsSaleApproved])

    const handleCheckoutModalClicks = (e) => {
        if(e.target.id === 'checkout_modal_back') return setIsCheckout(false); 
    }

    const handle_click_BUTTON_Add_To_Cart = (e, i) => {
        let tempState = [...shopContext.state.cartState]
        // let current_Order_Object = order_Details_Arr[i]
        // item,
        // quantity,
        // color,
        // extras 
        let current_Order_Object = new Order_Item(
            order_Details_Arr[i].item,
            order_Details_Arr[i].quantity,
            order_Details_Arr[i].color,
            order_Details_Arr[i].extras,
        )
        console.log(current_Order_Object);
        
        tempState.push(current_Order_Object)
        shopContext.action.setCartState(tempState)
    }

    const handleMouseOverCartItem = (i) => {
        setIsMouseOverCartItem(true)
        setMouseOverCartItemIndex(i)
    }

    const handleMouseLeaveCartItem = () => {
        setIsMouseOverCartItem(false)
        setMouseOverCartItemIndex(null)
    }

    const handle_CartItem_Delete = (i) => {
        let tempItems = [ ...shopContext.state.cartState ] 
        let newItems = tempItems.filter((item, ItemId) => { return ItemId != i })             
        shopContext.action.setCartState(newItems)
    }

    const handleCheckout = (e) => {
        if(shopContext.state.cartState.length) setIsCheckout(true)
    }

    const handleItemDetailsChange = (i, param, change) => {
        let temp_order_Details_Arr = [...order_Details_Arr]    
        temp_order_Details_Arr[i][param] = change
        set_Order_Details_Arr(temp_order_Details_Arr)
    }
    
  return (
    <>
    {/* <Animation imgs={[[1,2,3]]}></Animation> */}
    <CloudCenter>
        <CLOUDS_Animation_1 top={200} left={-600}></CLOUDS_Animation_1>
        <CLOUDS_Animation_1 top={800} left={100}></CLOUDS_Animation_1>
        <CLOUDS_Animation_1 top={1000} left={-300}></CLOUDS_Animation_1>
    </CloudCenter>
    { !isDev ?
        <ShopWrapper>
            {
                isCheckout && shopContext.state.cartState.length ? 
                <>
                    <CheckoutModal_BACKING id={'checkout_modal_back'} onClick={handleCheckoutModalClicks}>
                        <CheckoutModal id={'checkout_modal'}>
                            <CheckoutModal_ContentContainer>
                                <CheckoutModal_Header>Your Items</CheckoutModal_Header>
                                <CheckoutModal_ItemList>
                                    {
                                        shopContext.state.cartState.map((orderItem, i) => {
                                            
                                            return (
                                                <CheckoutModal_ItemList_Item>
                                                    <CartItem_Image 
                                                        src={orderItem.item.details.images[0]}
                                                />
                                                    <CartItem_Details>
                                                        <CartItem_Name>{orderItem.item.details.title}</CartItem_Name>
                                                        <CartItem_Size>{orderItem.item.details.size}</CartItem_Size>
                                                        <CartItem_Price>${orderItem.item.details.price}</CartItem_Price>
                                                    </CartItem_Details>
                                                </CheckoutModal_ItemList_Item>
                                            )
                                        })
                                    }
                                </CheckoutModal_ItemList>

                                <Paypal setIsSaleApproved={setIsSaleApproved} purchases={temp}/>
                            </CheckoutModal_ContentContainer>
                        </CheckoutModal>

                    </CheckoutModal_BACKING>
                </>
                :
                <></>
            }
            <ShopHeaderSection>
                <HeaderContent>
                    <PM_Animation/>
                </HeaderContent>
            </ShopHeaderSection>
            <Shop_Sidebar_Float>
                <SideBarTop></SideBarTop>
                <SideBarItemsContainer>
                    {
                        !shopContext.state.cartState.length ? 
                        <CartTextContainer>
                            <EmptyCartText>
                                No items
                            </EmptyCartText>
                        </CartTextContainer>
                        :
                        <>{
                            shopContext.state.cartState.map((orderItem, i) => {
                                return(
                                    <>
                                        <></>
                                        <CartItem onMouseOver={() => {handleMouseOverCartItem(i)}} >
                                        {
                                            isMouseOverCartItem && mouseOverCartItemIndex === i ?
                                            <CartItemHover onMouseLeave={handleMouseLeaveCartItem}>
                                                <CartItemHoverButton>Details</CartItemHoverButton>
                                                <CartItemHoverButton onClick={() => handle_CartItem_Delete(i)} className={'delete-cart-item'}>Delete</CartItemHoverButton>
                                            </CartItemHover>
                                            :
                                            <></>
                                        }
                                            <CartItem_Image 
                                                src={orderItem.item.details.images[0]}
                                            />
                                            <CartItem_Name>{orderItem.item.details.title}</CartItem_Name>
                                        
                                            <CartItem_OrderQuantity>
                                                {orderItem.quantity} x {orderItem.size}
                                            </CartItem_OrderQuantity>

                                            <CartItem_OrderPrice>
                                                ${orderItem.item.details.price * orderItem.quantity}
                                            </CartItem_OrderPrice>
                                        </CartItem>
                                    </>
                                )
                            })
                        }
                        </>
                    }
                </SideBarItemsContainer>
                <SideBarCheckoutSection>
                    <CheckoutButton onClick={handleCheckout}>Check Out</CheckoutButton>
                </SideBarCheckoutSection>
            </Shop_Sidebar_Float>
            <ShopSection>
                <ItemsWrapper_Outter>
                    <ItemsWrapper_Inner>
                        {
                            items.map((item, i) => {
                                return (
                                    <ShopItem>
                                        <ShopItemContent>
                                            <Item_Col1>
                                                <Col1_Main>
                                                    <Item_Title>
                                                        {item.details.title}
                                                    </Item_Title>
                                                    <Item_Featured_Image
                                                    src={item.details.images[Shop_Item_Images_State[i]]}
                                                    />
                                                </Col1_Main>
                                                <Col1_Images>
                                                    {
                                                        <Item_Images>
                                                            {
                                                                item.details.images.map((image, j) => {
                                                                    return (
                                                                        <Item_Image 
                                                                            src={image} 
                                                                            onClick={() => {
                                                                                let tempstate = [...Shop_Item_Images_State]
                                                                                tempstate[i] = j
                                                                                set_Shop_Item_Images_State(tempstate)
                                                                            }}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </Item_Images>
                                                    }
                                                </Col1_Images>
                                             </Item_Col1>    
                                             <Col_Divider />
                                             <Item_Col2>
                                                <Item_Description>
                                                    {item.details.description}
                                                </Item_Description>
                                                <Item_Price>
                                                    $ {item.details.price}
                                                </Item_Price>
                                                <Item_DetailsRow>
                                                        {
                                                            item.details.sizes.length > 0 ? 
                                                            <>
                                                                <Item_DetailsRow_Item>
                                                                    <Item_Sizes_Header>Size:</Item_Sizes_Header>
                                                                    <Sizes_Drop_Down onChange={(e) => handleItemDetailsChange(i, 'size', e.target.value)}>
                                                                        {
                                                                            item.details.sizes.map((size) => {   
                                                                                return (
                                                                                    <option value={size}>
                                                                                        {size}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Sizes_Drop_Down>
                                                                </Item_DetailsRow_Item>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            item.details.versions.length > 0 ? 
                                                            <>
                                                                <Item_DetailsRow_Item>
                                                                    <p>{item.details.versionType}</p>
                                                                    <Versions_Drop_Down onChange={(e) => handleItemDetailsChange(i, 'version', e)}>
                                                                        {
                                                                            item.details.versions.map((version) => {   
                                                                                return (
                                                                                    <option
                                                                                        value={version}
                                                                                    >
                                                                                        {version}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Versions_Drop_Down>
                                                                </Item_DetailsRow_Item>

                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    <Item_DetailsRow_Item>
                                                        <Item_Quantity_Header>Quantity:</Item_Quantity_Header>
                                                        <Item_Quantity_Input type="number" min={1} value={order_Details_Arr[i].quantity} step={1} onChange={(e) => handleItemDetailsChange(i, 'quantity', parseInt(e.target.value))}/>
                                                    </Item_DetailsRow_Item>
                                                </Item_DetailsRow>

                                                <BUTTON_Add_To_Cart onClick={
                                                    (e) => handle_click_BUTTON_Add_To_Cart( e, i )
                                                    }>Add</BUTTON_Add_To_Cart>
                                                <Item_Tags>
                                                    {
                                                        item.details.tags.map((tag) => {
                                                            return (
                                                                <Item_Tag
                                                                    onClick={() => {
                                                                        console.log('tag');
                                                                    }}
                                                                >
                                                                    {tag}
                                                                </Item_Tag>
                                                            )
                                                        })
                                                    }
                                                </Item_Tags>
                                             </Item_Col2>

                                        </ShopItemContent>
                                    </ShopItem>
                                )
                            })
                        }

                    </ItemsWrapper_Inner>

                </ItemsWrapper_Outter>
                <Shop_Sidebar>
                    {/* <SideBarTop></SideBarTop>
                    <SideBarItemsContainer></SideBarItemsContainer>
                    <SideBarCheckoutSection></SideBarCheckoutSection> */}
                </Shop_Sidebar>

            </ShopSection>
            {/* {
                isCheckout ? <Paypal setIsSaleApproved={setIsSaleApproved}>checkout</Paypal> : 
                <button onClick={() => {setIsCheckout(true)}}>Checkout</button>
            }
            {
                isSaleApproved && <SaleApproved></SaleApproved>
            }
         */}
        </ShopWrapper>
    :
    <>...soon</>}
    </>
  )
}

const CloudCenter = styled.div`
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0%;
    left: 50vw;

`

const Item_Quantity_Header = styled.p`
    margin-bottom: 5px;
`

const CartItem_OrderQuantity = styled.h3`
    font-size: 14px;
`

const CartItem_OrderPrice = styled.h3`
    font-size: 14px;
`

const CheckoutModal_Header = styled.h2`

`

const CheckoutModal_BACKING = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 200;
    background-color: rgba(0,0,0,0.5);
    /* pointer-events: none; */
    display: flex;
    justify-content: center;
    align-items: center;
`
const CheckoutModal_ItemList_Item = styled.div`
    width: 100%;
    height: 80px;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: flex-start; 
    gap: 20px;
    border-radius: 3px;
    background-color: white;
    color: black;
    border-radius: 10px;
    padding: 0 20px;
`

const CheckoutModal_ItemList = styled.div`
    width: 80%;
    height: 60%;
    background-color: green;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    overflow-y: scroll;
    padding: 10px;
`

const CheckoutModal_ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
`

const CheckoutModal = styled.div`
    position: relative;
    width: 80vw;
    height: 80vh;
    max-width: 400px;
    max-height: 600px;
    background-color: rgba(20 ,20 ,50 ,1);
    pointer-events: all;
    border-radius: 8px;
`
const Item_Sizes_Header = styled.p`
    margin-bottom: 5px;
` 

const Item_Quantity_Input = styled.input`
    width: 80px;
    height: 30px;
    padding-left: 5px;
    margin-bottom: 10px;
`

const Item_Price = styled.h3`
    margin-bottom: 10px;
    width: fit-content;
    color: rgba(0,200,0,1);
    cursor: help;

    &&:hover{
        color: lime;
    }
`

const Item_DetailsRow = styled.div`
    display: flex;

`
const Item_DetailsRow_Item = styled.div`
    margin-right: 5px;
`

const Item_Title = styled.h2``
const Item_Description = styled.p`
    margin-top: 20px;
    height: fit-content;
    width: 90%;
    overflow-y: scroll;
    margin-bottom: 10px;
    `

const Item_Images = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    justify-content: space-evenly;
    align-items: center;
    gap: 3px;

    @media (max-width: 549px) {
        transform: initial
        }
`

const Item_Image = styled.img`
    aspect-ratio: 1 / 1;
    width: 50%;

    &&:hover{

        box-shadow: 0 0 6px 1px #0ff; /* outer cyan */
        color: yellow;
        /* animation: glowing 5s infinite; */
        /* animation-direction: alternate; */
        /* z-index: -1; */
    }
    

`
const Item_Featured_Image = styled.img`
    width: 100%;
    padding: 8px 0;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    
`

const Item_Tags = styled.div`
    margin-top: 0px;

`

const Item_Tag = styled.a`
    text-decoration: underline;
    font-size: 12px;
    color: lightblue;
    padding: 0 2px;;
    cursor: pointer;
    &&:hover{
        color: royalblue;
    }
`
const Sizes_Drop_Down = styled.select`
    cursor: pointer;
    margin-bottom: 10px;

    width: 80px;
    height: 30px;
`
const Sizes_Drop_Down_Option = styled.option`

`

const Versions_Drop_Down = styled.select`
    width: 50px;
    height: 30px;
    margin-bottom: 10px;
    cursor: pointer;

`
const Versions_Drop_Down_Option = styled.option`

`
const BUTTON_Add_To_Cart = styled.button`
    cursor: pointer;
    width: 200px;
    height: 30px;
    margin-top: 10px;
`



const Col1_Main = styled.div`
    height: 100%;
    width: 80%;
`
const Col1_Images = styled.div`
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`

const Col_Divider = styled.div`
    width: 6px;
    height: 40%;
    background-color: black;
    opacity: 0.1;
    margin: 0 10px 0 0 ;

    @media (max-width: 860px) {
        width: 70%;
        height: 6px;
        margin: 10px 0 10px 0 ;
        display: none;
    }
`

const Item_Col1 = styled.div`
    display: flex;
    flex-direction: row;
    /* width: 50%; */
    height: 100%;
    max-height: 340px;
    width: 400px;
    min-width: 200px;

    @media (max-width: 860px) {

    }

`
const Item_Col2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
    /* gap: 10px; */
    width: 35%;
    min-width: 100px;
    height: 100%;
    padding: 30px 0;
    margin-bottom: 20px;
    

    @media (max-width: 860px) {
        padding: 10px 0;
        width: 75%;
        /* height: 200px; */
        height: fit-content;
    }


`

const EmptyCartText = styled.h3`
    width: 500px;
    /* height: 100px; */
    transform: rotate(90deg);
    margin-top: 15px;
    text-align: center;
    font-size: 3em;
    opacity: 0.1;

    @media (max-width: 549px) {
        transform: initial
        }
`

const CartTextContainer = styled.div`
    width: 400px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 549px) {
        width: 100%;
    }
`

const CheckoutButton = styled.button`
    width: 85px;
    height: 32px;
`

const CartItemHover = styled.div`
    position: absolute;
    padding: 10px;
    min-width: 100px;
    /* width: 100px; */
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    border-radius: 3px;
    background-color: rgba(0,0,0,0.4);
    gap: 10px;
    color: black;
    border-radius: 10px;
`

const CartItemHoverButton = styled.button`
    width: 80%;
    height: 30px;
    background-color: white;
    border: none;
    border-radius: 5px;

    &&.delete-cart-item{
        background-color: orange;
        color: white;
    }
`



const CartItem = styled.div`
    padding: 0px;
    min-width: 100px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    border-radius: 3px;
    background-color: white;
    gap: 5px;
    color: black;
    border-radius: 10px;


`

const CartItem_Image = styled.img`
    width: 70px;
    height: 70px;
    background-color: black;
    border-radius: 50%;
    aspect-ratio: 1 / 1;

`

const CartItem_Details = styled.div`
`

const CartItem_Name = styled.h5`
`

const CartItem_Price = styled.h5`
`

const CartItem_Size = styled.h5`
`

const SideBarTop = styled.div`
    width: 100%;
    height: 137px;
    /* background-color: green; */

    @media (max-width: 549px) {
        display: none;
    }
`

const SideBarItemsContainer = styled.div`
    width: 100%;
    height: calc( 100vh - 240px );
    /* max-height: 80vh; */
    background-color: rgba(20 ,20 ,50 ,1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2px 0 20px 0px;

    @media (max-width: 549px) {
        height: 100%;
        width: calc( 100vw - 150px );
        flex-direction: row;
        padding: 20px 20px;


    }


`
const SideBarCheckoutSection = styled.div`
    height: 150px;
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 549px) {
        height: 100%;
        width: 150px;

    }

`

const Shop_Sidebar_Float = styled.div`
    position: fixed;
    right: 7px;
    top: 0;
    z-index: 100;
    width: 15%;
    height: calc(100% - 5px); 
    min-width: 100px;
    max-width: 100px;
    /* background-color: white; */
    border: solid rgba(60,60,60,0.8) ;
    box-sizing: content-box;
    border-width: 3px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    
    @media (max-width: 249px) {
        display: none;
    }
    @media (max-height: 450px) {
        display: none;
    }

    @media (max-width: 549px) {
        display: none;
        /* top: initial;
        left: 0;
        bottom: 0;
        height: 120px;
        width: calc(100% - 5px); 
        min-width: 100px;
        max-width: 100%;
        flex-direction: row; */
    }
`

const ShopHeaderSection = styled.section`
    width: calc(100vw - 130px);
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;

`

const HeaderContent = styled.div`
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 100%;

    @media (max-height: 450){
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 549px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`


const ShopSection = styled.section`
    display: flex;
    flex-direction: row;

    @media (max-width: 549px) {
        margin-bottom: 150px;
    }
`

const ShopItemContent = styled.div`
    border-radius: 5px;
    width: 100%;
    max-width: 800px;
    height: 100%;
    background-color: rgba(240,240,255,0.25);
    padding: 18px 20px 20px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 860px) {
        flex-direction: column;
        max-width: 500px;

    }
    @media (max-width: 549px) {
        max-width: 450px;
    }

`

const ShopItem = styled.div`
    width: 100%;
    height: 420px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-height: 450px) {
        width: 100%;

    }

    @media (max-width: 1249px) {
        width: 100%;
 
    }

    @media (max-width: 860px) {
        flex-direction: column;
        height: 670px;

    }
`

const ItemsWrapper_Outter = styled.div`
    width: calc(100% - 130px);
    min-width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-height: 450px) {
        width: 100%;
    }
    @media (max-width: 549px) {
        width: 100%;
    }
`

const Shop_Sidebar = styled.div`
    position: relative;
    min-height: 300px; 
    min-width: 110px;
    max-width: 110px;
    background-color: none;

    @media (max-width: 1249px) {
        
    }
    @media (max-height: 450px) {
        display: none;
    }

    @media (max-width: 549px) {
        display: none;
    }
`

const ItemsWrapper_Inner = styled.div`
    position: relative;

    /* max-width: 1100px; */
    width: 100%;
    /* min-width: 600px; */
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    /* gap: 20px; */

`

const ShopWrapper = styled.div`
    width: 100vw;
    min-height: 100%;
    height: fit-content;

`



export default Shop;