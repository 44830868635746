import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { uuid } from 'uuidv4'


class Purchase{
    constructor(){
        this.reference_id = ''
        this.description = null
        this.amount = {
            currency_code: "CAD",
            value: null
        }
    }
}


const Paypal = ({setIsSaleApproved, purchases}) => {

    const paypal = useRef()

    useEffect(() => {
        window.paypal.Buttons({
            createOrder: ( data, actions, err ) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [...purchases]
                })
            },
            onApprove: async ( data, actions ) => {
                const order = await actions.order.capture()
                console.log("successful order: " + order)
                setIsSaleApproved(true)
            },
            onError: ( err ) => {
                console.log( err );
                
            }
        }).render(paypal.current)
    },[])

    return (
        <div>
            <div ref={paypal}></div>
        </div>
    )
}

export default Paypal;